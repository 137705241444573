<template>
  <div>
    {{ $t('in_advance')}}
  </div>
</template>

<script>
export default {
  name: 'Prepayment'
}
</script>

<style scoped>

</style>
